import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // private _user: any = null;
  helperService = new JwtHelperService();

  redirect = '';

  constructor() {}

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('auth');
    // Check whether the token is expired and return
    // true or false
    return token ? !this.helperService.isTokenExpired(token) : false;
  }

  public setRedirect(route: string) {
    this.redirect = route;
  }
}
