<div class="header">
  <a class="logo" href="https://www.grandemenus.com/" target="_blank"
    >Grande Menus</a
  >
  <div class="buttons">
    <div (click)="navigateToContact()">Contact Us</div>
    <div (click)="logout()">Logout</div>
  </div>
</div>
<div class="selectors">
  <div class="title">Select a Menu</div>
  <div
    *ngFor="let restaurant of restaurants"
    class="item"
    (click)="findMenu(restaurant)"
  >
    {{ restaurant }}
  </div>
  <div *ngIf="restaurants.length < 0">
    You do not have access to any menus. Please contact us for support.
  </div>
</div>
