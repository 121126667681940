<div class="header">
  <a class="logo" href="https://www.grandemenus.com/" target="_blank"
    >Grande Menus</a
  >
  <div class="buttons">
    <div (click)="backToMenu()">Back</div>
  </div>
</div>
<div class="content">
  <span
    >For general support, bugs, feature requests, or technical issues, please
    email support@grandemenus.com and we will get right back to you!</span
  >
</div>
