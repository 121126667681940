import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { CategoryComponent } from './category/category.component';
import { DescriptionTextPipe } from './category/descriptionText.pipe';
import { ItemComponent } from './item/item.component';
import { RouterModule } from '@angular/router';
import { ModifierComponent } from './modifier/modifier.component';
import { FormsModule } from '@angular/forms';
import { RestaurantPickerComponent } from './restaurant-picker/restaurant-picker.component';
import { PricePageComponent } from './price-page/price-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    HomeComponent,
    CategoryComponent,
    DescriptionTextPipe,
    ItemComponent,
    ModifierComponent,
    RestaurantPickerComponent,
    PricePageComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, FontAwesomeModule],
  exports: [
    HomeComponent,
    CategoryComponent,
    DescriptionTextPipe,
    ItemComponent,
    ModifierComponent,
    PricePageComponent,
  ],
})
export class MenuModule {}
