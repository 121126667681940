import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent {
  @ViewChild('f')
  resetForm?: NgForm;

  @ViewChild('g')
  newPasswordForm?: NgForm;

  message: string = null;
  codeRequested: boolean = false;
  resetSuccess: boolean = false;

  constructor(protected router: Router) {}

  async requestCode() {
    try {
      const response = await Auth.forgotPassword(this.resetForm.value.username);
      this.message =
        'An email with a reset code was sent to ' +
        response.CodeDeliveryDetails.Destination;
      this.codeRequested = true;
    } catch (error) {
      this.message = 'You should receive an email with a reset code.';
    }
  }

  async resetPassword() {
    try {
      const username = this.newPasswordForm.value.username;
      const code = this.newPasswordForm.value.code;
      const password = this.newPasswordForm.value.password;
      await Auth.forgotPasswordSubmit(username, code, password);
      this.resetSuccess = true;
      this.message = 'Your password has been reset. Please log in.';
    } catch (error) {}
  }

  navigateToLogin() {
    this.router.navigateByUrl('/login');
  }
}
