<div *ngIf="!codeRequested && !resetSuccess" class="forgot-password-form">
  <form (ngSubmit)="requestCode()" #f="ngForm">
    <div class="row">
      <label for="username">Username:</label>
      <input type="text" id="username" name="username" ngModel />
    </div>
    <button class="submit-button" type="submit">Request reset code</button>
  </form>
</div>

<div class="reset-password-form" *ngIf="codeRequested && !resetSuccess">
  <form (ngSubmit)="resetPassword()" #g="ngForm">
    <div class="row">
      <label for="username">Username:</label>
      <input
        type="text"
        autocapitalize="off"
        id="username"
        name="username"
        ngModel
      />
    </div>
    <div class="row">
      <label for="code">Code:</label>
      <input type="text" id="code" name="code" ngModel />
    </div>
    <div class="row">
      <label for="password">New Password:</label>
      <input type="password" id="password" name="password" ngModel />
    </div>
    <button class="submit-button" type="submit">Update password</button>
  </form>
</div>

<div class="message" *ngIf="message">
  <div class="text">{{ message }}</div>
  <div *ngIf="resetSuccess" class="navigate" (click)="navigateToLogin()">
    Go to login page
  </div>
</div>
