import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponet {
  constructor(protected location: Location) {}

  backToMenu() {
    this.location.back();
  }
}
