import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu.service';
import { Item } from '../models/item.model';
import { Menu } from '../models/menu.model';

@Component({
  selector: 'app-price-page',
  templateUrl: './price-page.component.html',
  styleUrls: ['./price-page.component.scss'],
})
export class PricePageComponent implements OnInit {
  currentMenu: Menu = this.menuService.currentMenu;
  items: Item[] = [];

  constructor(protected menuService: MenuService) {}

  ngOnInit(): void {
    this.currentMenu.categories.forEach((cat) => {
      cat.sub_categories.forEach((subCat) => {
        subCat.items.forEach((item) => {
          this.items.push(item);
        });
      });
      cat.items.forEach((item) => {
        this.items.push(item);
      });
    });
  }
}
