import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponet } from './contact/contact.component';
import { HomeComponent } from './menu/home/home.component';
import { PricePageComponent } from './menu/price-page/price-page.component';
import { RestaurantPickerComponent } from './menu/restaurant-picker/restaurant-picker.component';
import { AuthGuardService as AuthGuard } from './user-management/auth/auth.service';
import { LoginComponent } from './user-management/login/login.component';
import { PasswordResetComponent } from './user-management/password-reset/password-reset.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'passwordReset',
    component: PasswordResetComponent,
  },
  {
    path: 'contact',
    component: ContactComponet,
  },
  {
    path: 'edit',
    component: RestaurantPickerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit/:restaurantId',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit/:restaurantId/pricing',
    component: PricePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '*',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
