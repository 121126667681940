<div class="app-modifier">
  <div class="top-row" (click)="toggleExpansion()">
    <div class="name" *ngIf="!expanded">{{ modifier.name }}</div>
    <div *ngIf="expanded">&and;</div>
    <div *ngIf="!expanded">&or;</div>
  </div>

  <div *ngIf="expanded">
    <form class="form">
      <div class="top">
        <div class="form-field">
          <label for="modifiername">modifier name:</label>
          <input
            type="text"
            id="modifiername"
            name="name"
            [(ngModel)]="modifier.name"
          />
        </div>
        <div class="form-field">
          <label for="modifierminimum">Minimum required:</label>
          <input
            type="number"
            id="modifierminimum"
            name="minimum"
            [(ngModel)]="modifier.min"
          />
        </div>
        <div class="form-field">
          <label for="modifiermaximum">Maximum allowed:</label>
          <input
            type="number"
            id="modifiermaximum"
            name="maximum"
            [(ngModel)]="modifier.max"
          />
        </div>
      </div>

      <div *ngIf="isDetailMode" class="image-info">
        <div class="left-col">
          <div class="form-field">
            <label class="image-label" for="itemimage"
              >Upload or select image:</label
            >
            <input
              class="image-input"
              type="file"
              id="itemimage"
              name="image"
              (change)="modifierImageChange($event)"
              ngModel
            />
          </div>
        </div>

        <div class="right-col">
          <img
            class="thumbnail"
            [src]="modifier.image"
            *ngIf="modifier.image?.length > 0"
          />
          <div
            *ngIf="modifier.image?.length > 0"
            class="delete"
            (click)="deleteImage()"
          >
            Delete image
          </div>
        </div>
      </div>
    </form>

    <div class="items-container">
      <div class="button" (click)="openChildItemForm()">Add item +</div>
      <div
        class="child-item-row"
        *ngFor="
          let item of modifier.child_items;
          first as isFirst;
          last as isLast;
          index as i
        "
      >
        <div class="move up">
          <div *ngIf="!isFirst" (click)="moveUp(i)">&#8593;</div>
        </div>
        <div class="move down">
          <div *ngIf="!isLast" (click)="moveDown(i)">&#8595;</div>
        </div>
        <app-item
          class="child-item"
          [item]="item"
          [isModifierItem]="true"
          (deleteItemEvent)="deleteItem($event)"
          (updateItemEvent)="updateItem($event)"
        ></app-item>
      </div>
    </div>
    <div class="delete-row">
      <div (click)="deleteModifier()" class="delete">delete modifier</div>
    </div>
  </div>
</div>
<div class="add-item-container" *ngIf="showForm">
  <div class="item-form">
    <form class="form" (ngSubmit)="addChildItem()" #f="ngForm">
      <div class="row">
        <div class="column">
          <div class="form-field">
            <label for="itemname">Item name:</label>
            <input type="text" id="itemname" name="name" ngModel />
          </div>
          <div class="form-field">
            <label for="itemimage">Image URL:</label>
            <input type="text" id="itemimage" name="image" ngModel />
          </div>
          <div class="form-field">
            <label for="itemprice">Additional cost:</label>
            <input
              type="number"
              step="0.01"
              id="itemprice"
              name="price"
              ngModel
            />
          </div>
        </div>
        <div class="column">
          <div class="form-field">
            <label for="itemdescription">Description:</label>
            <textarea
              type="text"
              id="itemdescription"
              name="description"
              ngModel
              rows="4"
              cols="32"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <button class="submit-button" type="submit">Add Item</button>
        <div class="cancel" (click)="closeChildItemForm()">Cancel</div>
      </div>
    </form>
  </div>
</div>
