<div class="header">
  <a class="logo" href="https://www.grandemenus.com/" target="_blank"
    >Grande Menus</a
  >
  <div class="buttons">
    <div (click)="navigateToContact()">Contact Us</div>
  </div>
</div>
<div class="login-form" *ngIf="!requirePasswordUpdate">
  <form (ngSubmit)="login()" #f="ngForm">
    <div class="row">
      <label for="username">Username</label>
      <input type="text" id="username" name="username" ngModel />
    </div>
    <div class="row">
      <label for="password">Password</label>
      <input type="password" id="password" name="password" ngModel />
    </div>
    <button class="submit-button" type="submit">Login</button>
  </form>
</div>

<div class="login-form" *ngIf="requirePasswordUpdate">
  <div>Please set a new password for your account</div>
  <form (ngSubmit)="updatePassword()" #passwordForm="ngForm">
    <div class="row">
      <label for="password">New Password</label>
      <input type="password" id="password" name="password" ngModel />
    </div>
    <div class="row">
      <label for="passwordVerify">Re-enter Password</label>
      <input
        type="password"
        id="passwordVerify"
        name="passswordVerfiy"
        ngModel
      />
    </div>
    <button class="submit-button" type="submit">Update Password</button>
  </form>
</div>
<div class="below-form">
  <div class="error-message">
    {{ errorMessage | async }}
  </div>
  <div class="password-recovery" (click)="navigateToPasswordRecovery()">
    Forgot password?
  </div>
</div>
