import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import * as AWS from 'aws-sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from 'aws-amplify';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  state = this.router.routerState.snapshot;
  // lambda = new AWS.Lambda({
  //   accessKeyId: 'AKIAYZFSYW52G2VCYQLX',
  //   secretAccessKey: '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',
  //   region: 'us-west-1',
  // });

  private API =
    'https://7qvs4oz2m2.execute-api.us-west-1.amazonaws.com/defualt';

  constructor(
    public auth: UserService,
    public router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected http: HttpClient
  ) {}

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login'], {
        queryParams: {
          return: this.state.url,
        },
      });
      return false;
    }
    return true;
  }

  async getRestaurantIds(userId: string) {
    const token = sessionStorage.getItem('auth');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token ? token : '',
      }),
    };
    httpOptions.headers.set('access-control-allow-origin', '*');
    const requestUrl = this.API + '/restaurants';
    try {
      const response = await this.http
        .get(requestUrl + '?userName=' + userId, httpOptions)
        .toPromise();
      // this.userService.setUserName(userId);
      return response;
    } catch (err) {}
  }

  async refreshToken(): Promise<void> {
    const session = await Auth.currentSession();
    const jwt = session.getAccessToken().getJwtToken();
    sessionStorage.setItem('auth', jwt);
  }

  logout() {
    Auth.signOut();
    sessionStorage.removeItem('auth');
    sessionStorage.removeItem('userName');
    this.router.navigate(['/']);
  }
}
