<form class="form">
  <div *ngFor="let item of items" class="form-row">
    <div>{{ item.name }}</div>
    <label for="price">Price:</label>
    <input
      type="number"
      step="0.01"
      name="price"
      id="price"
      [(ngModel)]="item.price"
    />
  </div>
</form>

<form class="modify-all-prices-form">
  <label for="linear-increase"
    >Enter an amount to adjust the price of all items. Use a negative value to
    lower prices.</label
  >
  <input type="number" id="linear-increase" name="linear-increase" />
</form>
