import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { Menu } from '../models/menu.model';
import { Category } from '../models/category.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { ImageCdnService } from 'src/app/utilities/image-cdn.service';
import { LoadingService } from 'src/app/utilities/loading.service';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthGuardService } from 'src/app/user-management/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:oninput', ['$event'])
  handleKeyboardEvent() {
    this.menuService.setPendingChanges();
  }

  menuData: Menu | null;
  logo: string = '';
  showForm: boolean = false;
  @ViewChild('f')
  newCategoryForm?: NgForm;
  id = this.route.snapshot.paramMap.get('restaurantId');
  newCategoryImage: File | Blob;
  newLogoImage: File | Blob;
  pendingChanges$ = this.menuService.pendingChanges$;
  menuExpanded = false;
  menuIcon = faBars;
  showQRCode = false;
  qrCodeUrl: string;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected menuService: MenuService,
    protected route: ActivatedRoute,
    protected changeDetector: ChangeDetectorRef,
    protected imageCdnService: ImageCdnService,
    protected loadingServie: LoadingService,
    protected authService: AuthGuardService,
    protected loadingService: LoadingService
  ) {
    this.menuData = this.menuService.currentMenu;
  }

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('restaurantId');
    const userId = sessionStorage.getItem('userName');
    this.loadingService.showLoadingSpinner();
    try {
      const response: any = await this.authService.getRestaurantIds(userId);
      const restaurants: string[] = response.restaurants;
      if (!restaurants.includes(id)) {
        this.loadingService.hideLoadingSpinner();
        this.router.navigateByUrl('/');
      }
    } catch (err) {
      console.log('error', err);
      this.loadingService.hideLoadingSpinner();
      this.router.navigateByUrl('/');
    }
    this.loadingService.hideLoadingSpinner();
    if (this.menuData === null) {
      if (id) {
        this.loadingServie.showLoadingSpinner();
        const response = await this.menuService.retrieveMenu(id);
        this.loadingServie.hideLoadingSpinner();
        if (!response.externalLink) {
          response.externalLink = {
            buttonText: '',
            linkAddress: '',
          };
        }
        this.menuService.currentMenu = response;
        this.menuData = this.menuService.currentMenu;
        this.changeDetector.markForCheck();
        this.logo = this.menuData?.store.logo || '';
      }
    }
    if (!this.menuData.introMessage) {
      this.menuData.introMessage = '';
    }
    if (!this.menuData.externalLink) {
      this.menuData.externalLink = {
        buttonText: '',
        linkAddress: '',
      };
    }
    this.qrCodeUrl = this.imageCdnService.getQRCodeUrl(this.menuService.id);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  imageChange($event) {
    this.newCategoryImage = $event.target.files[0];
  }

  logoImageChange($event) {
    this.newLogoImage = $event.target.files[0];
    this.saveLogo();
  }

  async saveLogo() {
    console.log('saving');
    this.loadingServie.showLoadingSpinner();
    let imageUrl = '';
    try {
      imageUrl = await this.imageCdnService.uploadFile(
        this.menuService.id,
        this.newLogoImage
      );
      let tempMenu = this.menuData;
      tempMenu.store.logo = imageUrl;
      this.menuService.currentMenu = tempMenu;
      this.menuData = tempMenu;
      await this.saveChanges();
    } catch (error) {
      console.error('There was an issue uploading the image', error);
    }
    this.loadingServie.hideLoadingSpinner();
  }

  async deleteLogo() {
    if (
      confirm(
        `Are you sure you want to delete the logo? This action cannot be undone.`
      ) === true
    ) {
      const key = this.menuData.store.logo.split('com/')[1];
      this.loadingServie.showLoadingSpinner();
      try {
        // await this.imageCdnService.deleteFile(key);
        let tempMenu = this.menuData;
        tempMenu.store.logo = '';
        this.menuService.currentMenu = tempMenu;
        await this.saveChanges();
      } catch (error) {
        console.error('The image could not be deleted', error);
      }
      this.loadingServie.hideLoadingSpinner();
    }
  }

  addCategory() {
    this.showForm = true;
    document.getElementById('name').focus();
  }

  async saveCategory() {
    let tempMenu = this.menuData;
    const id = uuidv4();
    this.loadingServie.showLoadingSpinner();
    let imageUrl = '';
    try {
      imageUrl = await this.imageCdnService.uploadFile(
        this.menuService.id,
        this.newCategoryImage
      );
    } catch (e) {
      console.error('There was a problem uploading the image');
    }

    this.loadingServie.hideLoadingSpinner();
    let newCategory: Category = {
      name: this.newCategoryForm?.value.name,
      description: this.newCategoryForm?.value.description,
      items: [],
      image: imageUrl,
      id: id,
      sub_categories: [],
    };
    tempMenu?.categories.push(newCategory);
    this.menuService.currentMenu = tempMenu;
    this.menuData = tempMenu;
    this.changeDetector.markForCheck();
    this.showForm = false;
    this.saveChanges();
  }

  deleteCategory(category: Category) {
    if (
      confirm(
        `Are you sure you want to delete this Category and it's items? This action cannot be undone.`
      ) === true
    ) {
      const index = this.menuData.categories.findIndex(
        (c) => c.id === category.id
      );
      if (index > -1) {
        this.menuData.categories.splice(index, 1);
        this.saveChanges();
      }
    }
  }

  updateCategory(category: Category) {
    const index = this.menuData.categories.findIndex(
      (c) => c.id === category.id
    );
    if (index > -1) {
      this.menuData.categories.splice(index, 1, category);
      this.saveChanges();
    }
  }

  async saveChanges() {
    this.loadingServie.showLoadingSpinner();
    await this.menuService.saveMenu();
    this.loadingServie.hideLoadingSpinner();
  }

  closeCategoryForm() {
    this.showForm = false;
  }

  toggleQRCode() {
    this.showQRCode = !this.showQRCode;
  }

  logout() {
    this.authService.logout();
  }

  navigateToContact() {
    this.router.navigate(['/contact']);
  }

  goToMenus() {
    this.router.navigate(['/edit']);
  }

  moveUp(index: number) {
    const temp = this.menuData.categories[index];
    this.menuData.categories[index] = this.menuData.categories[index - 1];
    this.menuData.categories[index - 1] = temp;
    this.menuService.setPendingChanges();
  }

  moveDown(index: number) {
    const temp = this.menuData.categories[index];
    this.menuData.categories[index] = this.menuData.categories[index + 1];
    this.menuData.categories[index + 1] = temp;
    this.menuService.setPendingChanges();
  }
}
