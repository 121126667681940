<div class="app-item">
  <div class="top-row" (click)="toggleExpansion()">
    <div class="name" *ngIf="!expanded">{{ item.name }}</div>
    <div *ngIf="expanded">&and;</div>
    <div *ngIf="!expanded">&or;</div>
    <!-- <div
      *ngIf="expanded && !isModifierItem"
      class="save-button"
      (click)="addToMostPopular()"
    >
      Add item to 'most popular' category
    </div> -->
  </div>
  <div *ngIf="expanded">
    <form class="form">
      <div class="column">
        <div class="form-field">
          <label for="itemname">Item name:</label>
          <input
            type="text"
            id="itemname"
            name="name"
            [(ngModel)]="item.name"
          />
        </div>
        <div class="form-field">
          <label *ngIf="!isModifierItem" for="itemprice">Price:</label>
          <label *ngIf="isModifierItem" for="itemprice">Additional cost:</label>
          <input
            type="number"
            step="0.01"
            id="itemprice"
            name="price"
            [(ngModel)]="item.price"
          />
        </div>
        <div *ngIf="!isModifierItem" class="image-info">
          <div class="left-col">
            <div class="form-field">
              <label class="image-label" for="itemimage"
                >Upload or select image:</label
              >
              <input
                class="image-input"
                type="file"
                id="itemimage"
                name="image"
                (change)="itemImageChange($event)"
                ngModel
              />
            </div>
            <div *ngIf="availableItemImageKeys.length > 0" class="or">or</div>
            <div
              class="button"
              *ngIf="availableItemImageKeys.length > 0"
              (click)="openImagesModal()"
            >
              View available images
            </div>
          </div>

          <div class="right-col">
            <img
              class="thumbnail"
              [src]="item.image"
              *ngIf="item.image?.length > 0"
            />
            <div
              *ngIf="item.image?.length > 0"
              class="delete"
              (click)="deleteImage()"
            >
              Delete image
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="switch-container">
          <span *ngIf="item.isActive">Visible</span>
          <span *ngIf="!item.isActive">Hidden</span>
          <label class="switch">
            <input
              name="isActive"
              type="checkbox"
              [(ngModel)]="item.isActive"
              (click)="menuService.setPendingChanges()"
            />
            <span class="slider round"></span>
          </label>
        </div>

        <div class="form-field">
          <label for="itemdescription">Description:</label>
          <textarea
            type="text"
            id="itemdescription"
            name="description"
            [(ngModel)]="item.description"
            rows="4"
            cols="32"
          ></textarea>
        </div>
        <div class="checkbox-container">
          <input
            name="glutenFree"
            type="checkbox"
            [(ngModel)]="item.isGF"
            (click)="menuService.setPendingChanges()"
          />
          <label for="glutenFee">Gluten free</label>
        </div>
        <div class="checkbox-container">
          <input
            name="dairyFree"
            type="checkbox"
            [(ngModel)]="item.isDF"
            (click)="menuService.setPendingChanges()"
          />
          <label for="dairyFree">Dairy free</label>
        </div>
        <div class="checkbox-container">
          <input
            name="isVegan"
            type="checkbox"
            [(ngModel)]="item.isVegan"
            (click)="menuService.setPendingChanges()"
          />
          <label for="glutenFee">Vegan</label>
        </div>
      </div>
    </form>
    <div class="row"></div>
    <div class="modifiers-container">
      <div
        *ngFor="
          let modifier of item.child_modifiers;
          first as isFirst;
          last as isLast;
          index as i
        "
        class="modifier-row"
      >
        <div class="move up">
          <div *ngIf="!isFirst" (click)="moveUp(i)">&#8593;</div>
        </div>
        <div class="move down">
          <div *ngIf="!isLast" (click)="moveDown(i)">&#8595;</div>
        </div>
        <app-modifier
          class="modifier"
          [modifier]="modifier"
          [isDetailMode]="item.detailedItem"
          (deleteModifierEvent)="deleteChildModifier($event)"
          (updateModifierEvent)="updateChildModifier($event)"
        ></app-modifier>
      </div>
    </div>
    <div class="bottom-row">
      <div class="button" (click)="openNewChildModifierForm()">
        Add modifier +
      </div>
    </div>
    <div class="delete-row">
      <div (click)="deleteItem()" class="delete-item">Delete item</div>
    </div>
  </div>
</div>
<div class="add-modifier-container" *ngIf="showForm">
  <div class="modifier-form">
    <form class="form" (ngSubmit)="addChildModifier()" #f="ngForm">
      <div class="row">
        <div class="column">
          <div class="form-field">
            <label for="itemname">Modifier name:</label>
            <input type="text" id="name" name="name" ngModel />
          </div>
          <div class="form-field">
            <label for="min">Minimum count:</label>
            <input type="number" id="min" name="min" ngModel />
          </div>
          <div class="form-field">
            <label for="max">Maximum count:</label>
            <input type="number" id="max" name="max" ngModel />
          </div>
        </div>
      </div>

      <div class="row">
        <button class="submit-button" type="submit">Add Modifier</button>
        <div class="cancel" (click)="closeChildModifierForm()">Cancel</div>
      </div>
    </form>
  </div>
</div>

<div class="image-selector" *ngIf="showImageModal">
  <div class="container">
    <div class="close-button" (click)="closeImagesModal()">X</div>
    <div class="info">Available images:</div>
    <div class="images">
      <div
        *ngFor="let key of availableItemImageKeys; index as i"
        class="image-holder"
        (click)="updateItemImage(getImageUrl(key))"
      >
        <img [src]="getImageUrl(key)" />
      </div>
    </div>
  </div>
</div>
