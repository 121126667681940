<div class="app-category">
  <div class="top-row" (click)="toggleExpansion()">
    <div class="name" *ngIf="!expanded">{{ category.name }}</div>
    <div class="exp" *ngIf="expanded">&and;</div>
    <div class="exp" *ngIf="!expanded">&or;</div>
  </div>

  <div *ngIf="expanded">
    <form class="form">
      <div class="column">
        <div class="form-field">
          <label for="categoryname">Category name:</label>
          <input
            type="text"
            id="categoryname"
            [(ngModel)]="category.name"
            name="name"
          />
        </div>
        <div class="form-field">
          <label for="categorydescription">Description:</label>
          <textarea
            type="text"
            id="categorydescription"
            [(ngModel)]="category.description"
            name="description"
            rows="4"
            cols="48"
          ></textarea>
        </div>
      </div>

      <div class="form-field image-section">
        <div class="column">
          <label for="categoryimage">Upload Image:</label>
          <input
            type="file"
            id="categoryimage"
            (change)="catImageChange($event)"
            name="image"
          />
        </div>
        <div class="column">
          <img [src]="category.image" class="thumbnail" />
          <div
            *ngIf="category.image?.length > 0"
            class="delete"
            (click)="deleteImage()"
          >
            delete image
          </div>
        </div>
      </div>

      <div class="column"></div>
    </form>

    <div class="items-container">
      <div
        *ngFor="
          let item of category.items;
          first as isFirst;
          last as isLast;
          index as i
        "
        class="item-row"
      >
        <div class="move up">
          <div *ngIf="!isFirst" (click)="moveUp(i)">&#8593;</div>
        </div>
        <div class="move down">
          <div *ngIf="!isLast" (click)="moveDown(i)">&#8595;</div>
        </div>
        <app-item
          class="item"
          [ngClass]="item.isActive ? '' : 'inactive'"
          [item]="item"
          (deleteItemEvent)="deleteItem($event)"
          (updateItemEvent)="updateItem($event)"
        ></app-item>
      </div>
      <div class="button" (click)="openNewItemForm()" *ngIf="!showForm">
        Add item +
      </div>
      <div *ngIf="showForm">
        <div class="item-form">
          <form class="form" (ngSubmit)="addItem()" #f="ngForm">
            <div class="row">
              <div class="column">
                <div class="form-field">
                  <label for="itemname">Item name:</label>
                  <input type="text" id="itemname" name="name" ngModel />
                </div>

                <div class="form-field">
                  <label for="itemprice">Price:</label>
                  <input
                    type="number"
                    step="0.01"
                    id="itemprice"
                    name="price"
                    ngModel
                  />
                </div>
                <div class="form-field">
                  <label for="itemimage">Image:</label>
                  <input
                    type="file"
                    id="itemimage"
                    name="image"
                    (change)="newItemImageChange($event)"
                    ngModel
                  />
                </div>
              </div>
              <div class="column">
                <div class="form-field">
                  <label for="itemdescription">Description:</label>
                  <textarea
                    type="text"
                    id="itemdescription"
                    name="description"
                    ngModel
                    rows="4"
                    cols="32"
                  ></textarea>
                </div>
                <div class="checkbox-container">
                  <input
                    name="glutenFree"
                    type="checkbox"
                    ngModel
                    (click)="menuService.setPendingChanges()"
                  />
                  <label for="glutenFee">Gluten free</label>
                </div>
                <div class="checkbox-container">
                  <input
                    name="dairyFree"
                    type="checkbox"
                    ngModel
                    (click)="menuService.setPendingChanges()"
                  />
                  <label for="dairyFree">Dairy free</label>
                </div>
                <div class="checkbox-container">
                  <input
                    name="isVegan"
                    type="checkbox"
                    ngModel
                    (click)="menuService.setPendingChanges()"
                  />
                  <label for="glutenFee">Vegan</label>
                </div>
              </div>
            </div>

            <div class="row">
              <button class="submit-button" type="submit">Add Item</button>
              <div class="cancel" (click)="closeItemForm()">Cancel</div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <app-category
      class="sub-category"
      *ngFor="let cat of category.sub_categories"
      [category]="cat"
      [isSubCategory]="true"
      (updateSubCategoryEvent)="updateSubCategory($event)"
      (deleteSubCategoryEvent)="deleteSubCategory($event)"
    ></app-category>
    <div class="delete-row">
      <div (click)="delete()" class="delete">delete category</div>
    </div>
  </div>
</div>
<div class="add-item-container" *ngIf="showForm"></div>
