import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'descriptionText' })
export class DescriptionTextPipe implements PipeTransform {
  transform(value: string): string {
    if (value.length < 78) {
      return value;
    }
    return value.slice(0, 75) + '...';
  }
}
