import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Category } from './models/category.model';
import { Item } from './models/item.model';
import { Menu } from './models/menu.model';
import * as S3 from 'aws-sdk/clients/s3';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { LoadingService } from '../utilities/loading.service';
import { AuthGuardService } from '../user-management/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _currentMenu = new BehaviorSubject<Menu | null>(null);
  currentMenu$ = this._currentMenu.asObservable();
  id = '';

  private API = 'https://bmc4plmfx4.execute-api.us-west-1.amazonaws.com';

  pendingChanges$: Subject<boolean> = new Subject();

  constructor(
    protected router: Router,
    protected http: HttpClient,
    protected loadingService: LoadingService,
    protected authService: AuthGuardService
  ) {}

  get currentMenu(): Menu | null {
    return this._currentMenu.getValue();
  }

  set currentMenu(menu: Menu | null) {
    this._currentMenu.next(menu);
  }

  public retrieveMenu(id: string): Promise<Menu> {
    this.id = id;
    const token = sessionStorage.getItem('auth');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token ? token : '',
      }),
    };
    httpOptions.headers.set('Access-Control-Allow-Origin', this.API);
    const requestUrl = this.API + '/menu/' + id;
    return this.http.get<Menu>(requestUrl, httpOptions).toPromise();
  }

  public async saveMenu(): Promise<Object> {
    const token = sessionStorage.getItem('auth');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token ? token : '',
      }),
    };
    httpOptions.headers.set('Access-Control-Allow-Origin', this.API);
    const requestUrl = this.API + '/menu/' + this.id;
    this.pendingChanges$.next(false);
    const response = await this.http
      .post(requestUrl, this.currentMenu, httpOptions)
      .toPromise();
    await this.updateToken();
    this.pendingChanges$.next(false);
    this.authService.refreshToken();
    return response;
  }

  public setPendingChanges() {
    this.pendingChanges$.next(true);
  }

  async updateToken() {
    const newSession = await Auth.currentSession();
    sessionStorage.setItem('auth', newSession.getAccessToken().getJwtToken());
  }

  async addItemToMostPopularCategory(item: Item): Promise<void> {
    this.loadingService.showLoadingSpinner();
    if (this.currentMenu.categories.some((cat) => cat.is_most_popular)) {
      this.currentMenu.categories
        .filter((cat) => cat.is_most_popular)[0]
        .items.push(item);
    } else {
      let tempMenu = this.currentMenu;
      const id = uuidv4();

      let newCategory: Category = {
        name: 'Most popular',
        description: 'Our most loved and ordered items.',
        items: [item],
        id: id,
        sub_categories: [],
        is_most_popular: true,
      };
      tempMenu.categories = [newCategory, ...this.currentMenu.categories];
      this.currentMenu = tempMenu;
    }
    await this.saveMenu();
    this.loadingService.hideLoadingSpinner();
  }
}
