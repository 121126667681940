<div class="home">
  <div class="header">
    <a class="logo" href="https://www.grandemenus.com/" target="_blank"
      >Grande Menus</a
    >
    <div class="buttons">
      <div (click)="goToMenus()">Menus</div>
      <div (click)="toggleQRCode()">QR Code</div>
      <div (click)="navigateToContact()">Contact Us</div>
      <div (click)="logout()">Logout</div>
    </div>
  </div>
  <div class="qr-code" *ngIf="showQRCode">
    <img [src]="qrCodeUrl" />
  </div>
  <div class="content">
    <div class="logo-container">
      Logo
      <img [src]="menuData?.store.logo" class="image" />

      <div class="buttons">
        <input
          type="file"
          id="logoImage"
          name="logoImage"
          (change)="logoImageChange($event)"
        />
        <div class="delete-logo" (click)="deleteLogo()">Delete logo</div>
      </div>
    </div>
    <div class="logo-container">
      External Link<br />
      <span class="info">
        Places a button at the top of the menu that links to the provided url.
        Great for linking to your website, online ordering platforms, etc...
      </span>
      <form class="form">
        <label for="buttonText">Button text:</label>
        <input
          type="text"
          id="buttonText"
          name="buttonText"
          [(ngModel)]="menuData?.externalLink.buttonText"
        />
        <label for="url">URL:</label>
        <input
          type="text"
          id="url"
          name="url"
          [(ngModel)]="menuData?.externalLink.linkAddress"
        />
      </form>
    </div>

    <div class="intro-container">
      <form class="intro-message-form" *ngIf="menuData">
        <div class="column">
          <label for="intromessage">Menu intro message:</label>
          <textarea
            type="text"
            id="intromessage"
            [(ngModel)]="menuData.introMessage"
            name="intromessage"
            rows="4"
            cols="32"
          ></textarea>
        </div>
      </form>
    </div>
    <div
      *ngFor="
        let category of menuData?.categories;
        first as isFirst;
        last as isLast;
        index as i
      "
      class="category-row"
    >
      <div class="move up">
        <div *ngIf="!isFirst" (click)="moveUp(i)">&#8593;</div>
      </div>
      <div class="move down">
        <div *ngIf="!isLast" (click)="moveDown(i)">&#8595;</div>
      </div>
      <app-category
        [category]="category"
        class="category"
        (deleteCategoryEvent)="deleteCategory($event)"
        (updateCategoryEvent)="updateCategory($event)"
      >
      </app-category>
    </div>
    <div class="category-row new" *ngIf="showForm">
      <div class="category">
        <form class="new-form" (ngSubmit)="saveCategory()" #f="ngForm">
          <div class="row">New Category</div>
          <div class="row">
            <div class="column left">
              <div class="form-field">
                <label for="categoryname">Category name:</label>
                <input type="text" id="name" name="name" ngModel />
              </div>
              <div class="form-field">
                <label for="categorydescription">Description:</label>
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  rows="4"
                  cols="48"
                  ngModel
                ></textarea>
              </div>
            </div>

            <div class="column">
              <div class="form-field">
                <label for="categoryimage">Image:</label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  (change)="imageChange($event)"
                  ngModel
                />
              </div>
            </div>
          </div>
          <div class="row buttons">
            <button class="submit-button" type="submit">Add Category</button>
            <div class="cancel" (click)="closeCategoryForm()">Cancel</div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="!showForm" class="bottom-row">
      <div class="button" (click)="addCategory()">Add Category +</div>
    </div>
  </div>
</div>
<!-- <div class="add-category-container" *ngIf="showForm">
  <div class="category-form">
    <form class="form" (ngSubmit)="saveCategory()" #f="ngForm">
      <div class="row">
        <div class="column">
          <div class="form-field">
            <label for="categoryname">Category name:</label>
            <input type="text" id="name" name="name" ngModel />
          </div>
          <div class="form-field">
            <label for="categoryimage">Image:</label>
            <input
              type="file"
              id="image"
              name="image"
              (change)="imageChange($event)"
              ngModel
            />
          </div>
        </div>

        <div class="column">
          <div class="form-field">
            <label for="categorydescription">Description:</label>
            <textarea
              type="text"
              id="description"
              name="description"
              rows="4"
              cols="32"
              ngModel
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <button class="submit-button" type="submit">Add Category</button>
        <div class="cancel" (click)="closeCategoryForm()">Cancel</div>
      </div>
    </form>
  </div>
</div> -->

<div
  *ngIf="pendingChanges$ | async"
  class="save-button"
  (click)="saveChanges()"
>
  Save Pending Changes
</div>
