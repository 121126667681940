import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthGuardService } from 'src/app/user-management/auth/auth.service';
import { UserService } from 'src/app/user-management/user.service';
import { LoadingService } from 'src/app/utilities/loading.service';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-restaurant-picker',
  templateUrl: './restaurant-picker.component.html',
  styleUrls: ['./restaurant-picker.component.scss'],
})
export class RestaurantPickerComponent implements OnInit {
  @ViewChild('f')
  menuForm?: NgForm;
  restaurants: string[] = [];

  errMessage;

  constructor(
    protected menuService: MenuService,
    protected router: Router,
    protected loadingService: LoadingService,
    protected authService: AuthGuardService,
    protected userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    const userId = sessionStorage.getItem('userName');
    this.loadingService.showLoadingSpinner();
    try {
      const response: any = await this.authService.getRestaurantIds(userId);
      this.restaurants = response.restaurants.sort();
      if (this.restaurants.length < 1) {
        this.errMessage =
          'We did not find any restaurants for your user (' +
          userId +
          '). Please contact us for assitance.';
      }
    } catch (err) {
      console.log('error', err);
    }
    this.loadingService.hideLoadingSpinner();
  }

  async findMenu(menuName: string): Promise<void> {
    this.loadingService.showLoadingSpinner();
    const response = await this.menuService.retrieveMenu(menuName);

    this.loadingService.hideLoadingSpinner();
    this.menuService.currentMenu = response;
    this.router.navigate(['edit', menuName]);
  }

  logout() {
    this.authService.logout();
  }

  navigateToContact() {
    this.router.navigate(['/contact']);
  }
}
