import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Item } from '../models/item.model';
import { Modifier } from '../models/modifier.model';

import { v4 as uuidv4 } from 'uuid';
import { LoadingService } from 'src/app/utilities/loading.service';
import { MenuService } from '../menu.service';
import { ImageCdnService } from 'src/app/utilities/image-cdn.service';

@Component({
  selector: 'app-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.scss'],
})
export class ModifierComponent {
  @Input()
  modifier!: Modifier;

  @Input()
  isDetailMode: boolean = false;

  @Output()
  updateModifierEvent = new EventEmitter<Modifier>();

  @Output()
  deleteModifierEvent = new EventEmitter<Modifier>();

  @ViewChild('f')
  newChildItemForm?: NgForm;

  showForm: boolean = false;

  expanded: boolean = false;
  modifierImage: File | Blob;

  constructor(
    protected loadingService: LoadingService,
    protected menuService: MenuService,
    protected imageCdnService: ImageCdnService
  ) {}

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  updateModifier() {
    this.updateModifierEvent.emit(this.modifier);
  }

  deleteModifier() {
    if (
      confirm(
        `Are you sure you want to delete this modifier and it's items? This action cannot be undone.`
      ) === true
    ) {
      this.deleteModifierEvent.emit(this.modifier);
    }
  }

  openChildItemForm() {
    this.showForm = true;
    document.getElementById('itemname').focus();
  }

  closeChildItemForm() {
    this.showForm = false;
  }

  addChildItem() {
    const id = uuidv4();
    let newItem: Item = {
      id: id,
      name: this.newChildItemForm?.value.name,
      price: this.newChildItemForm?.value.price,
      description: this.newChildItemForm?.value.description,
      tags: [],
      child_modifiers: [],
      client_id: this.modifier.id,
    };
    this.modifier.child_items.push(newItem);
    this.updateModifier();
    this.closeChildItemForm();
  }

  async deleteItem(item: Item): Promise<void> {
    if (
      confirm(
        `Are you sure you want to delete this item and it's modifiers? This action cannot be undone.`
      ) === true
    ) {
      const index = this.modifier.child_items.findIndex(
        (i) => i.id === item.id
      );
      if (index > -1) {
        this.modifier.child_items.splice(index, 1);
        await this.updateModifier();
      }
    }
  }

  async updateItem(item: Item): Promise<void> {
    const index = this.modifier.child_items.findIndex((i) => i.id === item.id);
    if (index > -1) {
      this.modifier.child_items.splice(index, 1, item);
      await this.updateModifier();
    }
  }

  async modifierImageChange($event) {
    this.loadingService.showLoadingSpinner();
    this.modifierImage = $event.target.files[0];
    let newImageUrl = '';
    const prefix = this.menuService.id + '/' + this.modifier.id;
    try {
      newImageUrl = await this.imageCdnService.uploadFile(
        prefix,
        this.modifierImage
      );
    } catch (e) {
      console.error('There was a problem uploading the image');
    }

    this.loadingService.hideLoadingSpinner();
    this.modifier.image = newImageUrl;
    this.menuService.saveMenu();
  }

  moveUp(index: number) {
    const temp = this.modifier.child_items[index];
    this.modifier.child_items[index] = this.modifier.child_items[index - 1];
    this.modifier.child_items[index - 1] = temp;
    this.menuService.setPendingChanges();
  }

  moveDown(index: number) {
    const temp = this.modifier.child_items[index];
    this.modifier.child_items[index] = this.modifier.child_items[index + 1];
    this.modifier.child_items[index + 1] = temp;
    this.menuService.setPendingChanges();
  }
}
