import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Item } from '../models/item.model';
import { Modifier } from '../models/modifier.model';

import { v4 as uuidv4 } from 'uuid';
import { ImageCdnService } from 'src/app/utilities/image-cdn.service';
import { MenuService } from '../menu.service';
import { LoadingService } from 'src/app/utilities/loading.service';
import { Category } from '../models/category.model';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {
  @Input()
  item!: Item;

  @Input()
  isModifierItem?: boolean = false;

  @Output()
  deleteItemEvent = new EventEmitter<Item>();

  @Output()
  updateItemEvent = new EventEmitter<Item>();

  @ViewChild('f')
  newChildModifierForm?: NgForm;
  showForm: boolean = false;

  expanded: boolean = false;
  itemImage: File | Blob;

  availableItemImageKeys: string[] = [];
  images: [] = [];
  showImageModal: boolean = false;

  constructor(
    protected imageCdnService: ImageCdnService,
    protected menuService: MenuService,
    protected loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    if (this.item.isActive === undefined) {
      this.item.isActive = true;
    }
    if (this.item.isDF === undefined) {
      this.item.isDF = false;
    }
    if (this.item.isGF === undefined) {
      this.item.isGF = false;
    }
    if (this.item.isVegan === undefined) {
      this.item.isVegan = false;
    }
  }

  async itemImageChange($event) {
    this.loadingService.showLoadingSpinner();
    this.itemImage = $event.target.files[0];
    let newImageUrl = '';
    const prefix = this.menuService.id + '/' + this.item.id;
    try {
      newImageUrl = await this.imageCdnService.uploadFile(
        prefix,
        this.itemImage
      );
    } catch (e) {
      console.error('There was a problem uploading the image');
    }

    this.loadingService.hideLoadingSpinner();
    this.item.image = newImageUrl;
    this.getItemImages();
    this.menuService.saveMenu();
  }

  async updateItem() {
    if (this.itemImage) {
      let newImageUrl = '';
      const prefix = this.menuService.id + '/' + this.item.id;
      try {
        newImageUrl = await this.imageCdnService.uploadFile(
          prefix,
          this.itemImage
        );
      } catch (e) {
        console.error('There was a problem uploading the image');
        this.loadingService.hideLoadingSpinner();
      }

      this.loadingService.hideLoadingSpinner();
      this.item.image = newImageUrl;
    }
    this.updateItemEvent.emit(this.item);
    await this.getItemImages();
  }

  async addChildModifier() {
    const id = uuidv4();
    let newModifier: Modifier = {
      id: id,
      name: this.newChildModifierForm?.value.name,
      min: this.newChildModifierForm?.value.min,
      max: this.newChildModifierForm?.value.max,
      child_items: [],
      client_id: this.item.id,
      raw_name: '',
      serving_type: '',
    };
    this.item.child_modifiers.push(newModifier);
    this.menuService.saveMenu();
    this.showForm = false;
  }

  async toggleExpansion(): Promise<void> {
    this.expanded = !this.expanded;
    if (this.expanded) {
      await this.getItemImages();
    }
  }

  openNewChildModifierForm() {
    this.showForm = true;
    document.getElementById('name').focus();
  }

  closeChildModifierForm() {
    this.showForm = false;
  }

  deleteItem() {
    if (
      confirm(
        `Are you sure you want to delete this item and it's modifiers? This action cannot be undone.`
      ) === true
    ) {
      this.deleteItemEvent.emit(this.item);
    }
  }

  async deleteChildModifier(modifier: Modifier) {
    if (
      confirm(
        `Are you sure you want to delete this modifier? This action cannot be undone.`
      ) === true
    ) {
      const index = this.item.child_modifiers.findIndex(
        (cm) => cm.id === modifier.id
      );
      if (index > -1) {
        this.item.child_modifiers.splice(index, 1);
      }
      await this.menuService.saveMenu();
    }
  }

  async updateChildModifier(modifier: Modifier) {
    const index = this.item.child_modifiers.findIndex(
      (cm) => cm.id === modifier.id
    );
    if (index > -1) {
      this.item.child_modifiers.splice(index, 1, modifier);
    }
    await this.menuService.saveMenu();
  }

  async deleteImage(): Promise<void> {
    if (
      confirm(
        `Are you sure you want to delete this image? This action cannot be undone.`
      ) === true
    ) {
      this.item.image = '';
      this.loadingService.showLoadingSpinner();
      await this.menuService.saveMenu();
      this.loadingService.hideLoadingSpinner();
    }
  }

  async getItemImages(): Promise<void> {
    this.availableItemImageKeys = [];
    const imageRespone = await this.imageCdnService.getImagesForItem(
      this.menuService.id,
      this.item.id
    );
    if (imageRespone.Contents.length > 0) {
      this.availableItemImageKeys = imageRespone.Contents.map((obj) => obj.Key);
    }
  }

  async openImagesModal(): Promise<void> {
    this.showImageModal = true;
  }

  closeImagesModal(): void {
    this.showImageModal = false;
  }

  getImageUrl(key: string): string {
    return this.imageCdnService.getImageUrl(key);
  }

  async updateItemImage(url: string) {
    this.item.image = url;
    this.loadingService.showLoadingSpinner();
    await this.menuService.saveMenu();
    this.loadingService.hideLoadingSpinner();
    this.showImageModal = false;
  }

  addToMostPopular() {
    this.menuService.addItemToMostPopularCategory(this.item);
  }

  moveUp(index: number) {
    const temp = this.item.child_modifiers[index];
    this.item.child_modifiers[index] = this.item.child_modifiers[index - 1];
    this.item.child_modifiers[index - 1] = temp;
    this.menuService.setPendingChanges();
  }

  moveDown(index: number) {
    const temp = this.item.child_modifiers[index];
    this.item.child_modifiers[index] = this.item.child_modifiers[index + 1];
    this.item.child_modifiers[index + 1] = temp;
    this.menuService.setPendingChanges();
  }
}
