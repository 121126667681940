import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable, Subject } from 'rxjs';
import { LoadingService } from 'src/app/utilities/loading.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  requirePasswordUpdate = false;
  user: any = null;
  errorMessage: Subject<string> = new Subject();

  constructor(
    protected change: ChangeDetectorRef,
    protected router: Router,
    protected userService: UserService,
    protected loadingService: LoadingService
  ) {}

  @ViewChild('f')
  loginForm?: NgForm;

  @ViewChild('passwordForm')
  passwordForm?: NgForm;

  ngOnInit(): void {
    if (this.userService.isAuthenticated()) {
      this.router.navigate(['edit']);
    }
  }

  async login() {
    if (this.loginForm) {
      let userObj;
      try {
        this.loadingService.showLoadingSpinner();
        userObj = await Auth.signIn(
          this.loginForm.value.username,
          this.loginForm.value.password
        );
        sessionStorage.setItem('userName', this.loginForm.value.username);
        this.loadingService.hideLoadingSpinner();
        if (userObj && userObj.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.user = userObj;
          this.requirePasswordUpdate = true;
          this.change.markForCheck();
        } else {
          sessionStorage.setItem(
            'auth',
            userObj.signInUserSession.idToken.jwtToken
          );
          this.navigateToMenu();
        }
      } catch (error) {
        this.loadingService.hideLoadingSpinner();
        this.errorMessage.next('Incorrect username or password');
      }
    }
  }

  async updatePassword() {
    try {
      this.user = await Auth.completeNewPassword(
        this.user,
        this.passwordForm?.value.password
      );
      sessionStorage.setItem(
        'auth',
        this.user.signInUserSession.accessToken.jwtToken
      );
      this.navigateToMenu();
    } catch (error) {
      console.error('Unable to update your password', error);
      this.errorMessage = error;
    }
  }

  private navigateToMenu() {
    this.router.navigateByUrl('/edit');
  }

  public navigateToPasswordRecovery() {
    this.router.navigateByUrl('passwordReset');
  }

  navigateToContact() {
    this.router.navigate(['/contact']);
  }
}
