import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import * as AWS from 'aws-sdk';
import { timeStamp } from 'console';
import { S3 } from 'aws-sdk';

@Injectable({
  providedIn: 'root',
})
export class ImageCdnService {
  s3 = new AWS.S3({
    accessKeyId: 'AKIAYZFSYW52G2VCYQLX',
    secretAccessKey: '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',
    region: 'us-west-1',
  });

  BUCKET_NAME = 'grandeimages';

  URL = 'https://grandeimages.s3.us-west-1.amazonaws.com/';

  async uploadFile(prefix: string, imageData: any) {
    const extension = imageData.name.split('.').pop();
    const fileName = uuidv4();

    const params = {
      Bucket: this.BUCKET_NAME,
      Key: `${prefix}/${fileName}.${extension}`,
      Body: imageData,
    };

    const response = await this.s3.upload(params).promise();

    return response.Location;
  }

  async getImagesForItem(
    restaurantId: string,
    itemId: string
  ): Promise<AWS.S3.ListObjectsV2Output> {
    const params = {
      Bucket: this.BUCKET_NAME,
      Prefix: restaurantId + '/' + itemId,
    };
    const urls = await this.s3.listObjectsV2(params).promise();
    return urls;
  }

  getImageUrl(key: string): string {
    return this.URL + key;
  }

  getQRCodeUrl(restaurantId: string) {
    return this.URL + restaurantId + '/qrcode.png';
  }

  // async deleteFile(imageKey: string): Promise<void> {
  //   const params = {
  //     Bucket: this.BUCKET_NAME,
  //     Key: imageKey,
  //   };
  //   await this.s3.deleteObject(params).promise();
  // }
}
