import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  isLoading$: Subject<boolean> = new Subject();

  constructor() {
    this.isLoading$.next(false);
  }

  showLoadingSpinner() {
    this.isLoading$.next(true);
  }

  hideLoadingSpinner() {
    this.isLoading$.next(false);
  }
}
