import { Component } from '@angular/core';
import { LoadingService } from './utilities/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoggedIn = false;
  loading$ = this.loadingService.isLoading$;

  constructor(protected loadingService: LoadingService) {}
}
