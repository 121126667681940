import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { Category } from '../models/category.model';
import { Item } from '../models/item.model';
import { v4 as uuidv4 } from 'uuid';
import { ImageCdnService } from 'src/app/utilities/image-cdn.service';
import { MenuService } from '../menu.service';
import { LoadingService } from 'src/app/utilities/loading.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  @Input()
  category!: Category;

  @Input()
  isSubCategory: boolean = false;

  @Output()
  updateSubCategoryEvent = new EventEmitter<Category>();

  @Output()
  deleteSubCategoryEvent = new EventEmitter<Category>();

  @Output()
  deleteCategoryEvent = new EventEmitter<Category>();

  @Output()
  updateCategoryEvent = new EventEmitter<Category>();

  @ViewChild('f')
  newItemForm?: NgForm;
  showForm: boolean = false;

  @ViewChild('catform')
  newSubCatForm?: NgForm;

  name!: string;
  description!: string;

  expanded: boolean = false;
  categoryImage: File | Blob;
  newItemImage: File | Blob;

  image = null;

  constructor(
    protected imageCdnService: ImageCdnService,
    protected menuService: MenuService,
    protected loadingService: LoadingService
  ) {}

  async ngOnInit() {
    if (!this.category.sub_categories) {
      this.category.sub_categories = [];
    }
    this.name = this.category.name;
    this.description = this.category.description;
    this.image = this.category.image;
  }

  async catImageChange($event) {
    if ($event.target.files[0]) {
      this.loadingService.showLoadingSpinner();
      this.categoryImage = $event.target.files[0];
      let newCatImageUrl = '';
      const prefix = this.menuService.id;
      try {
        newCatImageUrl = await this.imageCdnService.uploadFile(
          prefix,
          this.categoryImage
        );
      } catch (e) {
        console.error('There was a problem uploading the image');
      }

      this.loadingService.hideLoadingSpinner();
      this.category.image = newCatImageUrl;
      this.menuService.saveMenu();
    }
  }

  newItemImageChange($event) {
    this.newItemImage = $event.target.files[0];
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  delete() {
    if (this.isSubCategory) {
      if (
        confirm(
          `Are you sure you want to delete this category and its items? This action cannot be undone.`
        )
      ) {
        this.deleteSubCategoryEvent.emit(this.category);
      }
    } else {
      if (
        confirm(
          `Are you sure you want to delete this category and its items? This action cannot be undone.`
        ) === true
      ) {
        this.deleteCategoryEvent.emit(this.category);
      }
    }
  }

  async addItem() {
    const id = uuidv4();
    this.loadingService.showLoadingSpinner();
    let imageUrl = '';
    try {
      imageUrl = await this.imageCdnService.uploadFile(
        this.menuService.id,
        this.newItemImage
      );
    } catch (e) {
      console.error('There was a problem uploading the image');
    }

    this.loadingService.hideLoadingSpinner();
    const newItem: Item = {
      id: id,
      name: this.newItemForm?.value.name,
      price: this.newItemForm?.value.price,
      description: this.newItemForm?.value.description,
      image: imageUrl,
      tags: [],
      child_modifiers: [],
      client_id: this.category.id,
      isActive: true,
      isDF: this.newItemForm?.value.dairyFree,
      isGF: this.newItemForm?.value.glutenFee,
      isVegan: this.newItemForm?.value.isVegan,
    };
    if (!this.category.items) {
      this.category.items = [];
    }
    this.category.items.push(newItem);
    await this.menuService.saveMenu();
    this.closeItemForm();
  }

  openNewItemForm() {
    this.showForm = true;
    document.getElementById('itemname').focus();
  }

  closeItemForm() {
    this.newItemImage = null;
    this.showForm = false;
  }

  async deleteItem(item: Item): Promise<void> {
    if (
      confirm(
        `Are you sure you want to delete this item and it's modifiers? This action cannot be undone.`
      ) === true
    ) {
      const index = this.category.items.findIndex((i) => i.id === item.id);
      if (index > -1) {
        this.category.items.splice(index, 1);
        await this.menuService.saveMenu();
      }
    }
  }

  async updateSubCategory(subCategory: Category): Promise<void> {
    const index = this.category.sub_categories.findIndex(
      (cat) => cat.id === subCategory.id
    );
    if (index > -1) {
      this.category.sub_categories.splice(index, 1, subCategory);
      await this.menuService.saveMenu();
    }
  }

  async deleteSubCategory(subCategory: Category): Promise<void> {
    if (
      confirm(
        `Are you sure you want to delete this category and it's items? This action cannot be undone.`
      ) === true
    ) {
      const index = this.category.sub_categories.findIndex(
        (cat) => cat.id === subCategory.id
      );
      if (index > -1) {
        this.category.sub_categories.splice(index, 1);
        await this.menuService.saveMenu();
      }
    }
  }

  async deleteImage(): Promise<void> {
    this.category.image = '';
    this.image = '';
    this.loadingService.showLoadingSpinner();
    await this.menuService.saveMenu();
    this.loadingService.hideLoadingSpinner();
  }

  moveUp(index: number) {
    const temp = this.category.items[index];
    this.category.items[index] = this.category.items[index - 1];
    this.category.items[index - 1] = temp;
    this.menuService.setPendingChanges();
  }

  moveDown(index: number) {
    const temp = this.category.items[index];
    this.category.items[index] = this.category.items[index + 1];
    this.category.items[index + 1] = temp;
    this.menuService.setPendingChanges();
  }
}
