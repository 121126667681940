import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { PasswordResetComponent } from './password-reset/password-reset.component';

@NgModule({
  declarations: [LoginComponent, PasswordResetComponent],
  imports: [CommonModule, FormsModule],
  exports: [LoginComponent, PasswordResetComponent],
})
export class UserManagementModule {}
